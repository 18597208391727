[class*="col-"] {
  float:left;
}
.home-content{
  .home-text{
    float:left;
  }
  .home-image{
    float:right;
  }
}

.region-header{
  float:left;
  margin-top:28px;
}
.region-navigation{
  float:right;
  width:100%;
  max-width: 470px;
  .add-phone{
    width:100%;
    float:right;
  }
  .meanmenu-reveal{
    position: absolute;
    top:80px;
    float:right;
    .mobile-text {
      line-height: 30px;
      strong{
        margin-left: 12px;
      }
    }
  }
}
.header {
  &.fixed{
    .region-navigation{
      max-width: 430px;
      .meanmenu-reveal{
        top:60px;
      }
    }
    .logo-wrapper{
      margin-bottom: 20px;
    }
  }
}
